import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Game } from '../../types/types';
import { FaThumbsUp, FaRegThumbsUp } from 'react-icons/fa';
import { Info } from 'lucide-react';

// Function to format numbers into millions or thousands
const formatToMillionsOrThousands = (value: number): string => {
  if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(2)}M`;
  } else if (value >= 1_000) {
    return `${(value / 1_000).toFixed(2)}K`;
  } else {
    return value.toFixed(2);
  }
};

// Function to format odds
const formatOdds = (odds: number | undefined | null): string => {
  if (odds === undefined || odds === null || isNaN(odds)) return 'N/A';
  return `1 in ${formatToMillionsOrThousands(odds)}`;
};

// Function to get a smiley face based on composite score
const getSmileyFace = (score: number | undefined): string => {
  if (score === undefined || score === null) return '🤔'; // Default to 'WTF?' if undefined
  if (score >= 80) {
    return '😄'; // Excellent
  } else if (score >= 60) {
    return '😊'; // Good
  } else if (score >= 40) {
    return '😐'; // Average
  } else if (score >= 20) {
    return '😕'; // Below Average
  } else {
    return '😞'; // Poor
  }
};

// Function to get a gradient color based on composite score
const getGradientColor = (score: number | undefined): string => {
  if (score === undefined || score === null) return 'text-gray-400'; // Gray if undefined

  const clampedScore = Math.min(Math.max(score, 0), 100);

  if (clampedScore >= 75) {
    return 'text-green-500'; // Green for scores 75 and above
  } else if (clampedScore >= 60) {
    return 'text-yellow-400'; // Yellow for scores between 60 and 74.99
  } else if (clampedScore >= 40) {
    return 'text-orange-400'; // Orange for scores between 40 and 59.99
  } else {
    return 'text-red-500'; // Red for scores below 40
  }
};

const GameComparisonCard: React.FC<{ game: Game; number?: number; onClick?: () => void; isSelected?: boolean; }> = ({ game, number, onClick, isSelected }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const likedGames = JSON.parse(localStorage.getItem('likedGames') || '[]');
    if (likedGames.includes(game.game_number)) {
      setIsLiked(true);
    }
  }, [game.game_number]);

  const handleLikeClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const likedGames = JSON.parse(localStorage.getItem('likedGames') || '[]');
    
    if (isLiked) {
      const updatedLikedGames = likedGames.filter((id: number) => id !== Number(game.game_number));
      localStorage.setItem('likedGames', JSON.stringify(updatedLikedGames));
    } else {
      likedGames.push(game.game_number);
      localStorage.setItem('likedGames', JSON.stringify(likedGames));
    }
    setIsLiked(!isLiked);
  };

  const handleTooltipToggle = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setShowTooltip((prev) => !prev);
  }, []);

  const handleTooltipClose = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const winAnyPrizePercentage = game.ProbOfWinAnyPrizePercent > 1 
    ? game.ProbOfWinAnyPrizePercent.toFixed(2) 
    : (game.ProbOfWinAnyPrizePercent * 100).toFixed(2);

  return (
    <motion.div 
      className={`p-6 rounded-lg shadow-lg relative h-full transition-all duration-300
        ${isSelected ? 'bg-gradient-to-r from-gray-900 to-gray-700 border-2 border-blue-400' : 'bg-gradient-to-r from-gray-900 to-gray-700 border border-gray-700'} 
        ${isSelected ? 'shadow-2xl' : 'shadow-lg'} cursor-pointer`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      onClick={onClick}
    >
      <div className="flex justify-between items-center mb-2">
        <div>
          <h3 className="text-xl font-bold text-green-500">{game.game_name}</h3><br />
          <p className="text-sm font-bold text-gray-300">
            <span className="text-gray-300">💵 Tkt cost:</span> 
            <span className="text-green-500"> ${game.ticket_price}</span>
          </p>
        </div>
        <button 
          className={`text-xl ${isLiked ? 'text-green-400' : 'text-gray-400 hover:text-green-400'}`}
          onClick={handleLikeClick}
          title={isLiked ? 'Remove from Favorites' : 'Add to Favorites'}
        >
          {isLiked ? <FaThumbsUp /> : <FaRegThumbsUp />}
        </button>
      </div>
      <div className="text-gray-300 font-bold">
        <div className="grid grid-cols-2 gap-5 mb-4">
          <div>
            <p className="text-xs">
              <span className="text-gray-300">🎯 Win Any Prize:</span> 
              <span className="text-green-500"> {winAnyPrizePercentage}%</span>
            </p>
            <p className="text-xs">
              <span className="text-gray-300">🎲 Current Odds:</span> 
              <span className="text-green-500"> {formatOdds(game.currentOdds)}</span>
            </p>
          </div>
          <div>
            <p className="text-xs">
              <span className="text-gray-300">🏆 Win Potential:</span> 
              <span className="text-green-500"> {game.winPotential.toFixed(2)}</span>
            </p>
            <p className={`text-xs font-bold text-gray-300`}>
              <span className="text-gray-300">📈 ROI:</span> 
              <span className="text-green-500"> {game.ROI.toFixed(2)}%</span>
            </p>
          </div>
        </div>

        {/* Composite Score Section */}
        <div className="text-center mb-2">
          <p className={`text-lg font-bold ${getGradientColor(game.compositeScore)}`}>
            Composite Score: {game.compositeScore !== undefined ? game.compositeScore.toFixed(2) : 'N/A'}
          </p>
        </div>

        <div className="absolute bottom-4 right-4 text-xl">
          <span>{getSmileyFace(game.compositeScore)}</span>
        </div>

        <motion.button
          onClick={handleTooltipToggle}
          className="absolute bottom-4 left-4 text-gray-400 hover:text-gray-300 focus:outline-none"
          initial={{ scale: 1 }}
          animate={{
            scale: [1, 1.2, 1],
            transition: {
              duration: 1,
              times: [0, 0.5, 1],
              repeat: 1,
              repeatDelay: 1
            }
          }}
        >
          <motion.div
            className="absolute inset-0 bg-yellow-300 rounded-full opacity-50"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{
              scale: [0.8, 1.2, 0.8],
              opacity: [0, 0.7, 0],
              transition: {
                duration: 2,
                repeat: 0,
                repeatDelay: 1
              }
            }}
          />
          <Info size={16} className="relative z-10" />
        </motion.button>

        <AnimatePresence>
          {showTooltip && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
              onClick={handleTooltipClose}
            >
              <motion.div
                initial={{ scale: 0.95, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.95, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="bg-gray-800 p-4 rounded-lg shadow-lg max-w-sm mx-4 text-white text-center"
                onClick={(e) => e.stopPropagation()}
              >
                <p>The Composite Score is a comprehensive measure of a scratch-off ticket's value. It takes into account factors such as the ticket price, odds of winning, and potential return on investment. This score helps you quickly assess the overall attractiveness of a ticket, balancing various aspects to give you a clear picture of its value proposition. The Win Potential indicates the potential return on your investment for this particular game.</p>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default GameComparisonCard;

