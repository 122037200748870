import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Zap, Shield, Star, MapPin, TrendingUp } from 'lucide-react';
import emailjs from 'emailjs-com';

const LandingPagebetaover: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      await emailjs.send(
        'service_dfpl57d',
        'template_nva1yn3',
        {
          to_name: 'Scratch Maestro Team',
          from_name: 'Beta Over Subscriber',
          message: `New subscriber email: ${email}`,
          reply_to: email,
          user_email: email
        },
        'UoAvN-P74K6HjK3Jb'
      );
      setSubmitMessage("Thank you for your interest! We'll keep you updated.");
      setEmail('');
    } catch (error) {
      console.error('Error sending email:', error);
      setSubmitMessage("Oops! Something went wrong. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <main className="container mx-auto px-4 py-16">
        {/* Hero Section */}
        <section className="text-center mb-16">
          <motion.h1
            className="text-4xl md:text-6xl font-extrabold mb-4 text-neon-green"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Scratch Maestro: Beta Phase Testing Complete!
          </motion.h1>
          <motion.p
            className="text-xl md:text-2xl mb-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            Thank you for helping us prove the viability of our project!
          </motion.p>
        </section>

        {/* Thank You Message */}
        <section className="bg-gray-800 rounded-lg p-8 mb-16">
          <motion.h2
            className="text-3xl font-bold mb-4 text-neon-green"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            A Heartfelt Thank You
          </motion.h2>
          <motion.p
            className="text-lg mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            We want to express our sincere gratitude to everyone who participated in our open beta testing phase. Your feedback, support, and enthusiasm have been invaluable in shaping Scratch Maestro Pro.
          </motion.p>
          <motion.p
            className="text-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            Thanks to your contributions, we've successfully proven the viability of our project and gathered crucial insights to make Scratch Maestro Pro even better.
          </motion.p>
        </section>

        {/* Next Phase */}
        <section className="bg-gray-800 rounded-lg p-8 mb-16">
          <motion.h2
            className="text-3xl font-bold mb-4 text-neon-green"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            What's Next?
          </motion.h2>
          <motion.p
            className="text-lg mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            We're hard at work developing the next phase of Scratch Maestro Pro. Our team is leveraging all the feedback we collected during the beta to create an even more powerful and user-friendly experience.
          </motion.p>
          <motion.p
            className="text-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            Stay tuned for exciting updates and new features that will revolutionize your scratch-off lottery experience!
          </motion.p>
        </section>

        {/* Features Preview */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-center text-neon-green">What to Expect in the Future</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              { icon: Zap, title: "Enhanced AI Algorithms", description: "Even more accurate predictions and insights." },
              { icon: Shield, title: "Advanced Security", description: "Cutting-edge protection for your data and privacy." },
              { icon: Star, title: "Personalized Experience", description: "Tailored recommendations based on your play style." },
              { icon: MapPin, title: "Location-based Features", description: "Find the best games near you with ease." },
              { icon: TrendingUp, title: "AI Trend Analysis", description: "Spot winning patterns with our advanced AI analytics." },
            ].map((feature, index) => (
              <motion.div
                key={index}
                className="bg-gray-800 p-6 rounded-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
              >
                <feature.icon className="w-12 h-12 text-neon-green mb-4" />
                <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                <p>{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Call to Action */}
        <section className="text-center">
          <motion.h2
            className="text-3xl font-bold mb-4 text-neon-green"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Stay Connected
          </motion.h2>
          <motion.p
            className="text-lg mb-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            Don't miss out on our launch! Sign up for updates and be the first to know when Scratch Maestro is ready.
          </motion.p>
          <motion.form
            className="max-w-md mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
            onSubmit={handleSubmit}
          >
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full px-4 py-2 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-neon-green bg-gray-700"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              type="submit"
              className="mt-4 px-6 py-2 bg-neon-green text-gray-900 font-bold rounded-r-lg hover:bg-green-400 transition duration-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Keep Me Posted'}
            </button>
            {submitMessage && (
              <p className="mt-2 text-sm text-center text-neon-green">
                {submitMessage}
              </p>
            )}
          </motion.form>
        </section>
      </main>

      <footer className="bg-gray-800 py-8 mt-16">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; {new Date().getFullYear()} Scratch Maestro Pro. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPagebetaover;
