import React from 'react';
import { Game } from '../../types/types';
import { motion } from 'framer-motion';
import { FaDollarSign, FaChartLine, FaDice, FaTrophy } from 'react-icons/fa';

interface GameCardProps {
  game: Game;
  isDangerZone?: boolean;
  number?: number;
}

export const GameCard: React.FC<GameCardProps> = ({ game, isDangerZone = false, number }) => {

  const formatOdds = (odds: number | null | undefined): string => {
    if (odds === null || odds === undefined) return 'N/A';
    return `1 in ${odds.toFixed(2)}`;
  };

  const getValueScoreColor = (score: number): string => {
    if (score >= 80) return 'text-green-500';
    if (score >= 60) return 'text-yellow-400';
    if (score >= 40) return 'text-orange-400';
    return 'text-red-500';
  };

  return (
    <motion.div
      className={`relative bg-gray-800 p-4 rounded-lg h-auto border-2 shadow-lg ${isDangerZone ? 'border-red-500' : 'border-yellow-400'}`}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.2 }}
    >
      {number !== undefined && (
        <div className="absolute top-2 left-2 bg-gray-700 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs font-bold">
          {number}
        </div>
      )}
      <div className="text-center mb-2">
        <h4 className={`font-bold text-sm mb-2 text-green-500`}>{game.game_name.trim()}</h4>
      </div>
      <div className="ml-8">
        <p className="text-xs mb-1 font-bold text-gray-300">
          <FaDollarSign className="inline-block mr-1 text-green-400" />
          Price: <span className="text-green-500">${game.ticket_price}</span>
        </p>
        <p className="text-xs mb-1 font-bold text-gray-300">
          <FaChartLine className="inline-block mr-1 text-blue-400" />
          ROI: <span className="text-blue-500">{game.ROI.toFixed(2)}%</span>
        </p>
        <p className="text-xs mb-1 font-bold text-gray-300">
          <FaDice className="inline-block mr-1 text-purple-400" />
          Current Odds: <span className="text-purple-500">{formatOdds(game.currentOdds)}</span>
        </p>
        <p className="text-xs mb-1 font-bold text-gray-300">
          <FaTrophy className="inline-block mr-1 text-yellow-400" />
          Win Potential: <span className="text-yellow-500">{game.winPotential.toFixed(2)}</span>
        </p>
        <p className={`text-xs mb-1 font-bold text-gray-300`}>
          Win Any Prize: <span className="text-green-500">
            {game.ProbOfWinAnyPrizePercent > 1 ? 
              (game.ProbOfWinAnyPrizePercent).toFixed(2) : 
              (game.ProbOfWinAnyPrizePercent * 100).toFixed(2)}%
          </span>
        </p>
        <p className={`text-md font-bold mt-1 ${getValueScoreColor(game.compositeScore)}`}>
          Composite Score: {game.compositeScore.toFixed(2)}
        </p>
      </div>
    </motion.div>
  );
};

export default GameCard;
