import React, { useState } from 'react';
import { motion } from 'framer-motion';

const ResponsibleGamblingAccordion: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <motion.div 
      className="frosted-glass bg-gray-800 text-white p-4 rounded-lg shadow-md border border-green-500 mb-1"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div 
        className="flex justify-between items-center cursor-pointer p-1 bg-green-900 rounded-lg border border-green-500"
        onClick={toggleAccordion}
      >
        <h3 className="text-lg font-bold text-white">Gambling Support</h3>
        <span className="text-white">{isOpen ? '▼' : '▲'}</span>
      </div>
      {isOpen && (
        <div className="p-4 bg-gray-900 mt-2 rounded-lg border border-green-500">
          <h4 className="text-md font-semibold text-green-400">Resources for Help:</h4>
          <ul className="mt-2 text-sm">
            <li><strong>National Problem Gambling Helpline</strong>: 1-800-522-4700 (Available 24/7)</li>
            <li><a href="https://www.gamblersanonymous.org" className="text-green-300 hover:underline">Gamblers Anonymous</a></li>
            <li><a href="https://www.begambleaware.org" className="text-green-300 hover:underline">BeGambleAware</a></li>
            <li><a href="https://www.gamcare.org.uk" className="text-green-300 hover:underline">GamCare</a></li>
          </ul>
          <div className="mt-4">
            <h4 className="text-md font-semibold text-yellow-300">Disclaimer:</h4>
            <p className="text-sm mt-2 text-gray-400">
              No Guarantees: While we strive to provide accurate and up-to-date information, we cannot guarantee the accuracy or completeness of any data. Our insights are designed to help you make informed decisions but do not guarantee winning outcomes.
            </p>
            <p className="text-sm mt-2 text-gray-400">
              Indemnity: By using our service, you agree that Scratch Maestro is not responsible for any financial losses or other damages. You agree to indemnify and hold harmless Scratch Maestro from any claims or legal actions arising from your use of our service.
            </p>
            <p className="text-sm mt-4 text-gray-400 text-center">
              Please remember to gamble responsibly and within your means.
            </p>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default ResponsibleGamblingAccordion;
