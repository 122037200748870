import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Game } from '../../types/types';
import { FaDollarSign, FaTrophy, FaTicketAlt, FaDice, FaChartLine, FaChevronDown, FaChevronUp } from 'react-icons/fa';
interface GameModalComparisonProps {
  game: Game;
  index: number;
  isOpen: boolean;
  onClose: () => void;
  customStyles?: React.CSSProperties;
}

const GameModalComparison: React.FC<GameModalComparisonProps> = ({ game, index, isOpen, onClose, customStyles }) => {
  const [isInterpretationExpanded, setIsInterpretationExpanded] = useState(false);

  if (!isOpen) return null;


  const formatOdds = (odds: number | null | undefined): string => {
    if (odds === null || odds === undefined) return 'N/A';
    return `1 in ${odds.toFixed(2)}`;
  };

  const getValueScoreColor = (score: number): string => {
    if (score >= 80) return 'text-green-500';
    if (score >= 60) return 'text-yellow-400';
    if (score >= 40) return 'text-orange-400';
    return 'text-red-500';
  };

  return (
    <motion.div 
      className="absolute left-0 right-0 mx-auto max-w-xl bg-gray-900 bg-opacity-80 text-white rounded-lg shadow-lg p-4 z-50 frosted-glass"
      style={{ ...customStyles, top: `${index * 280}px` }}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      onClick={onClose}
    >
      <h3 className="text-xl font-bold text-green-400 mb-4">{game.game_name}</h3>
      <div className="space-y-2 text-gray-300 font-bold">
        <p className="text-sm mb-1">
          <FaDollarSign className="inline-block mr-1 text-green-400" />
          Price: <span className="text-green-500">${game.ticket_price}</span>
        </p>
        <p className="text-sm mb-1">
          <FaChartLine className="inline-block mr-1 text-blue-400" />
          ROI: <span className="text-blue-500">{game.ROI.toFixed(2)}%</span>
        </p>
        <p className="text-sm mb-1">
          <FaDice className="inline-block mr-1 text-purple-400" />
          Current Odds: <span className="text-purple-500">{formatOdds(game.currentOdds)}</span>
        </p>
        <p className="text-sm mb-1">
          <FaTrophy className="inline-block mr-1 text-yellow-400" />
          Win Potential: <span className="text-yellow-500">{game.winPotential.toFixed(2)}</span>
        </p>
        <p className="text-sm mb-1">
          <FaTicketAlt className="inline-block mr-1 text-green-400" />
          Win Any Prize: <span className="text-green-500">
            {game.ProbOfWinAnyPrizePercent > 1 ? 
              (game.ProbOfWinAnyPrizePercent).toFixed(2) : 
              (game.ProbOfWinAnyPrizePercent * 100).toFixed(2)}%
          </span>
        </p>
        <p className={`text-md font-bold mt-1 ${getValueScoreColor(game.compositeScore)}`}>
          Composite Score: {game.compositeScore.toFixed(2)}
        </p>
        
        <div className="mt-4 bg-gray-800 rounded-lg">
          <button 
            className="w-full p-4 flex justify-between items-center text-sm text-gray-200 font-semibold"
            onClick={(e) => {
              e.stopPropagation();
              setIsInterpretationExpanded(!isInterpretationExpanded);
            }}
          >
            Composite Score Interpretation
            {isInterpretationExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </button>
          <AnimatePresence>
            {isInterpretationExpanded && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="overflow-hidden"
              >
                <p className="p-4 text-sm text-gray-400">
                  With a Win Potential {game.ROIInterpretation.split("with a Win Potential")[1].trim()}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
};
export default GameModalComparison;
