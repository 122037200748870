import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Game } from '../../types/types';
import ScratchCard from './ScratchCard';

interface GameModalProps {
  game: Game | null;
  isOpen: boolean;
  onClose: () => void;
  customStyles: { border: string; shadow: string };
}

const GameModal: React.FC<GameModalProps> = ({ game, isOpen, onClose, customStyles }) => {
  if (!game) return null;

  // Function to format odds in "1 in x.xx" format
  const formatOdds = (odds: number | undefined | null): string => {
    if (odds === undefined || odds === null || isNaN(odds)) {
      return 'N/A';
    }
    return `1 in ${odds.toFixed(2)}`;
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="frosted-glass fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose} // Close modal when tapping anywhere on the screen
        >
          <motion.div
            className={`bg-gray-900 p-6 rounded-lg shadow-xl relative w-full max-w-4xl ${customStyles.border} ${customStyles.shadow}`}
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
          >
            <ScratchCard game={game} />
            <div className="mt-4 text-gray-300">
              <p className="text-lg font-bold">
                Current Odds: <span className="text-green-500">{formatOdds(game.currentOdds)}</span>
              </p>
              <p className="text-lg font-bold">
                Original Odds: <span className="text-red-500">{formatOdds(game.original_odds)}</span>
              </p>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default GameModal;