import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Mail, Send, X } from 'lucide-react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';

const ContactUs: React.FC = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState(localStorage.getItem('userEmail') || '');
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSending(true);
    setError('');

    // Get the logged-in user's email from localStorage or your auth state
    const userEmail = localStorage.getItem('userEmail') || 'Not logged in';

    // Include the user's email in the message
    const fullMessage = `Message from: ${userEmail}\n\n${message}`;

    try {
      await emailjs.send(
        'service_dfpl57d',
        'template_nva1yn3',
        { 
          to_name: 'Scratch Maestro Team',
          from_name: name,
          message: fullMessage,
          reply_to: email,
          user_email: userEmail
        },
        'UoAvN-P74K6HjK3Jb'
      );
      setIsSent(true);
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Error sending email:', error);
      setError('Failed to send message. Please try again later.');
    } finally {
      setIsSending(false);
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleClose}>
      <motion.div
        className="bg-gray-900 text-white p-8 rounded-lg max-w-3xl max-h-[90vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.3 }}
      >
        <button
          onClick={handleClose}
          className="absolute top-0 right-0 p-2 text-white hover:text-neon-green"
        >
          <X size={24} />
        </button>
        <h1 className="text-4xl font-bold mb-8 text-center text-neon-green">Contact Us</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">Get in Touch</h2>
            {isSent ? (
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                className="bg-green-500 text-white p-4 rounded-md mb-4"
              >
                Thank you for your message! We'll get back to you soon.
              </motion.div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="name" className="block mb-1">Name</label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="w-full p-2 bg-gray-800 rounded"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block mb-1">Email</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full p-2 bg-gray-800 rounded"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block mb-1">Message</label>
                  <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    className="w-full p-2 bg-gray-800 rounded"
                    rows={4}
                  ></textarea>
                </div>
                <button 
                  type="submit" 
                  className="bg-neon-green text-gray-900 py-2 px-4 rounded hover:bg-green-400 transition duration-300 flex items-center justify-center"
                  disabled={isSending}
                >
                  {isSending ? (
                    <span className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Sending...
                    </span>
                  ) : (
                    <span className="flex items-center">
                      <Send className="mr-2" size={18} />
                      Send Message
                    </span>
                  )}
                </button>
                {error && <p className="text-red-500 mt-2">{error}</p>}
              </form>
            )}
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">Contact Information</h2>
            <div className="space-y-4">
              <p className="flex items-center">
                <Mail className="mr-2 text-neon-green" /> scratchmaestropro@gmail.com
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ContactUs;