import React from 'react';
import { motion } from 'framer-motion';
import { FileText, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const TermsOfService: React.FC = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleClose}>
      <motion.div
        className="bg-gray-900 text-white p-8 rounded-lg max-w-3xl max-h-[90vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.3 }}
      >
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 p-2 text-white hover:text-neon-green"
        >
          <X size={24} />
        </button>
        <h1 className="text-4xl font-bold mb-8 text-center text-neon-green flex items-center justify-center">
          <FileText className="mr-2" size={36} />
          Terms of Service
        </h1>
        
        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">1. Acceptance of Terms</h2>
            <p>By accessing or using Scratch Maestro, you agree to be bound by these Terms of Service and all applicable laws and regulations.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">2. Use of Service</h2>
            <p>You agree to use Scratch Maestro only for lawful purposes and in accordance with these Terms. You are responsible for maintaining the confidentiality of your account information.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">3. Subscription and Payments</h2>
            <p>Certain features of Scratch Maestro require a paid subscription. You agree to pay all fees associated with your subscription. We reserve the right to change our fees upon reasonable notice.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">4. Disclaimer of Warranties</h2>
            <p>Scratch Maestro is provided "as is" without any warranties, expressed or implied. We do not guarantee any specific results from the use of our service.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">5. Limitation of Liability</h2>
            <p>We shall not be liable for any indirect, incidental, special, consequential or punitive damages resulting from your use of or inability to use Scratch Maestro.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">6. Changes to Terms</h2>
            <p>We reserve the right to modify these Terms at any time. Your continued use of Scratch Maestro after any changes indicates your acceptance of the new Terms.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">7. Contact</h2>
            <p>If you have any questions about these Terms, please contact us at: terms@scratchmaestro.com</p>
          </section>
        </div>
      </motion.div>
    </div>
  );
};

export default TermsOfService;