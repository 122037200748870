import React from 'react';
import { NavLink } from 'react-router-dom';
import { User, Home, BarChart2 } from 'lucide-react';
import { motion } from 'framer-motion';

export const BottomNavbar: React.FC = () => {
  // Use require with a path relative to the src directory
  const carbonFibreImg = require('../../assets/carbonfibre.jpg');

  return (
    <nav 
      className="fixed bottom-0 left-0 right-0 bg-gray-900 p-4 flex justify-around border-t-2 border-green-500 border-top-highlight z-50"
      style={{ '--carbonfibre-bg': `url(${carbonFibreImg})` } as React.CSSProperties}
    >
      {/* Carbonfibre background */}
      <div className="absolute inset-0 bg-carbonfibre opacity-30 mix-blend-screen pointer-events-none"></div>
      
      {/* Navbar Icons - These are above the overlay */}
      <NavbarIcon to="/profile" icon={<User size={32} />} />
      <NavbarIcon to="/home" icon={<Home size={32} />} />
      <NavbarIcon to="/compare" icon={<BarChart2 size={32} />} />
    </nav>
  );
};

const NavbarIcon: React.FC<{ to: string; icon: React.ReactNode }> = ({ to, icon }) => (
  <motion.div
    whileHover={{ scale: 1.2 }}
    whileTap={{ scale: 0.9 }}
    className="flex flex-col items-center glow-wrapper"
  >
    <NavLink
      to={to}
      className={({ isActive }) => isActive ? "text-green-500" : "text-white"}
    >
      <div className="glow-container">
        {icon}
      </div>
    </NavLink>
  </motion.div>
);

export default BottomNavbar;