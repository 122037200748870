import { useState, useEffect } from 'react';
import { FaTrophy, FaSortAmountDownAlt, FaListAlt } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion'; // Import AnimatePresence

import GameComparisonCard from '../components/game/GameComparisonCard';
import GameModalComparison from '../components/game/gameModalComparison';
import Header from '../components/layout/Header';
import BottomNavbar from '../components/layout/BottomNavbar';

import { Game } from '../types/types';


// Filter options type
type FilterOption = 'top' | 'bottom' | 'all' | 'price';

const ComparisonPage: React.FC = () => {
  const [filterOption, setFilterOption] = useState<FilterOption>('top');
  const [selectedPrice, setSelectedPrice] = useState<number | null>(null);
  const [allGames, setAllGames] = useState<Game[]>([]);
  const [topChoices, setTopChoices] = useState<Game[]>([]);
  const [bottomChoices, setBottomChoices] = useState<Game[]>([]);
  const [selectedGames, setSelectedGames] = useState<Game[]>([]);
  const [animationKey, setAnimationKey] = useState(0);

  // Initialize game data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const selectedState = localStorage.getItem('selectedState') || 'tx';
        const response = await fetch(`https://api.scratchmaestro.com/lottery-data/${selectedState}`);
        const data = await response.json();
        setAllGames(data.allGames);
        setTopChoices(data.topChoices);
        setBottomChoices(data.bottomChoices);
      } catch (error) {
        console.error('Error fetching lottery data:', error);
      }
    };

    fetchData();
  }, []);

  // Filter and sort games based on selected option
  const filteredGames = (): Game[] => {
    let gamesToFilter: Game[] = [];
    
    switch (filterOption) {
      case 'top':
        gamesToFilter = topChoices;
        break;
      case 'bottom':
        gamesToFilter = bottomChoices;
        break;
      case 'all':
        gamesToFilter = allGames;
        break;
      case 'price':
        gamesToFilter = allGames.filter(game => game.ticket_price === selectedPrice);
        break;
    }

    // Sort games by value score in descending order (best at the top)
    return gamesToFilter.sort((a, b) => (b.valueScore || 0) - (a.valueScore || 0));
  };

  // Handle filter change
  const handleFilterChange = (option: FilterOption) => {
    setFilterOption(option);
    if (option !== 'price') {
      setSelectedPrice(null);
    }
    // Increment the animation key to trigger re-render
    setAnimationKey(prev => prev + 1);
  };

  // Toggle game selection for comparison
  const toggleSelectGame = (game: Game) => {
    setSelectedGames(prevSelected => {
      if (prevSelected.some(g => g.game_number === game.game_number)) {
        return prevSelected.filter(g => g.game_number !== game.game_number);
      } else if (prevSelected.length < 2) {
        return [...prevSelected, game];
      }
      return prevSelected;
    });
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-900">
      <Header />
      <main className="flex-grow pt-6 pb-20">
        <div className="comparison-page max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Selected Games for Comparison */}
          <SelectedGamesComparison selectedGames={selectedGames} toggleSelectGame={toggleSelectGame} />

          {/* Filter Controls */}
          <FilterControls 
            filterOption={filterOption} 
            handleFilterChange={handleFilterChange}
            selectedPrice={selectedPrice}
            setSelectedPrice={setSelectedPrice}
            allGames={allGames}
          />

          {/* Wrap GameCardGrid with AnimatePresence */}
          <AnimatePresence mode="wait">
            <motion.div
              key={animationKey}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 25,
                duration: 0.5
              }}
            >
              <GameCardGrid 
                games={filteredGames()} 
                selectedGames={selectedGames} 
                toggleSelectGame={toggleSelectGame} 
              />
            </motion.div>
          </AnimatePresence>
        </div>
      </main>
      <BottomNavbar />
    </div>
  );
};

// Selected Games Comparison Component
const SelectedGamesComparison: React.FC<{
  selectedGames: Game[];
  toggleSelectGame: (game: Game) => void;
}> = ({ selectedGames, toggleSelectGame }) => (
  <div className="selected-games space-y-2 mb-6 sticky top-16 z-40">
    {selectedGames.map((game, index) => (
      <GameModalComparison 
        key={game.game_number} 
        game={game} 
        index={index} 
        isOpen={true} 
        onClose={() => toggleSelectGame(game)}
        customStyles={{
          padding: '20px',
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)',
        }}
      />
    ))}
  </div>
);

// Filter Controls Component
const FilterControls: React.FC<{
  filterOption: FilterOption;
  handleFilterChange: (option: FilterOption) => void;
  selectedPrice: number | null;
  setSelectedPrice: React.Dispatch<React.SetStateAction<number | null>>;
  allGames: Game[];
}> = ({ filterOption, handleFilterChange, selectedPrice, setSelectedPrice, allGames }) => (
  <div className="filter-controls flex justify-center items-center space-x-8 py-15 rounded-lg shadow-lg p-3 border frosted-glass border-gray-600 max-w-full sticky top-16 z-30"
       style={{ paddingTop: '8px', background: 'linear-gradient(135deg, rgba(17, 20, 31, 0.9) 0%, rgba(36, 39, 48, 0.9) 10%)' }}>
    <FilterButton 
      option="top" 
      currentFilter={filterOption} 
      onClick={() => handleFilterChange('top')} 
      icon={FaTrophy} 
      label="Top 10" 
    />
    <FilterButton 
      option="bottom" 
      currentFilter={filterOption} 
      onClick={() => handleFilterChange('bottom')} 
      icon={FaSortAmountDownAlt} 
      label="Bottom 10" 
    />
    <FilterButton 
      option="all" 
      currentFilter={filterOption} 
      onClick={() => handleFilterChange('all')} 
      icon={FaListAlt} 
      label="All Games" 
    />
    <PriceSelector 
      filterOption={filterOption} 
      selectedPrice={selectedPrice} 
      setSelectedPrice={setSelectedPrice} 
      handleFilterChange={handleFilterChange} 
      allGames={allGames} 
    />
  </div>
);

// Filter Button Component
const FilterButton: React.FC<{
  option: FilterOption;
  currentFilter: FilterOption;
  onClick: () => void;
  icon: React.ElementType;
  label: string;
}> = ({ option, currentFilter, onClick, icon: Icon, label }) => (
  <button 
    className={`glassmorphism flex flex-col items-center justify-center w-12 h-12 rounded transition-all duration-300 
    ${currentFilter === option ? 'bg-green-600 text-white shadow-lg transform scale-105 glow-effect' : 'bg-gray-800 text-gray-300 hover:bg-green-500 hover:text-white'}`}
    onClick={onClick}
  >
    <Icon size={20} />
    <span className="mt-1 font-bold text-xs">{label}</span>
  </button>
);

// Price Selector Component
const PriceSelector: React.FC<{
  filterOption: FilterOption;
  selectedPrice: number | null;
  setSelectedPrice: React.Dispatch<React.SetStateAction<number | null>>;
  handleFilterChange: (option: FilterOption) => void;
  allGames: Game[];
}> = ({ filterOption, selectedPrice, setSelectedPrice, handleFilterChange, allGames }) => (
  <div className={`glassmorphism flex flex-col items-center justify-center w-13 h-12 rounded transition-all duration-300 
          ${filterOption === 'price' ? 'bg-green-600 text-white shadow-lg transform scale-105 glow-effect' : 'bg-gray-800 text-gray-300 hover:bg-green-500 hover:text-white'} relative`}>
    <div className="flex items-center w-full h-full">
      <select
        id="price-selector"
        name="price"
        className={`w-full h-full font-bold bg-transparent mt-1 text-xs text-white rounded appearance-none outline-none text-center px-1`}
        style={{ backgroundColor: 'transparent' }}
        value={selectedPrice || 'select'}
        onChange={(e) => {
          setSelectedPrice(Number(e.target.value));
          handleFilterChange('price');
        }}
      >
        <option value="select" disabled>Tkt $</option>
        {Array.from(new Set(allGames.map(game => game.ticket_price)))
          .sort((a, b) => a - b)
          .map(price => (
            <option key={price} value={price} className="bg-gray-800 text-white">
              ${price}
            </option>
        ))}
      </select>
    </div>
  </div>
);

// Game Card Grid Component
const GameCardGrid: React.FC<{
  games: Game[];
  selectedGames: Game[];
  toggleSelectGame: (game: Game) => void;
}> = ({ games, selectedGames, toggleSelectGame }) => (
  <motion.div 
    className="game-cards grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mt-6 px-2 sm:px-4"
  >
    {games.map((game, index) => (
      <motion.div
        key={game.game_number}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          type: "spring",
          stiffness: 300,
          damping: 20,
          delay: index * 0.05
        }}
      >
        <GameComparisonCard 
          game={game} 
          number={index + 1} 
          isSelected={selectedGames.some(g => g.game_number === game.game_number)} 
          onClick={() => toggleSelectGame(game)} 
        />
      </motion.div>
    ))}
  </motion.div>
);

export default ComparisonPage;
