import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import LandingPage from './pages/LandingPage';
import LandingPageBetaOver from './pages/LandingPageBetaOver'; // Import the new component
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import ComparisonPage from './pages/ComparisonPage';
import { Loading } from './components/common/Loading';
import { configureAmplify } from './amplifyConfig';
import { CoolLoading } from './components/common/CoolLoading';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeCheckout from './components/checkout/StripeCheckout';
import { checkSubscriptionStatus } from './services/apiService';
import { SuccessPage } from './pages/SuccessPage';
import { Header } from './components/layout/Header';
import { BottomNavbar } from './components/layout/BottomNavbar';
import ContactUs from './pages/ContactUs';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';

const stripePromise = loadStripe('pk_live_51PtwD9Il1kDXADoNd74od6lnUnbQ17oVhGsHEgVXqgByfqTqvoFsXequaagOtY56RZTxgzemCNCm4avG8KBozYSQ005UKjfHsw');


const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { authStatus, user } = useAuthenticator((context) => [context.authStatus, context.user]);
  const [hasSubscription, setHasSubscription] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus === 'unauthenticated') {
      navigate('/login');
    } else if (authStatus === 'authenticated') {
      const checkSubscription = async () => {
        if (user) {
          try {
            const status = await checkSubscriptionStatus(user.username);
            setHasSubscription(status);
            if (!status) {
              navigate('/checkout');
            }
          } catch (error) {
            console.error('Error checking subscription status:', error);
            setHasSubscription(false);
          }
        }
      };
      checkSubscription();
    }
  }, [authStatus, user, navigate]);

  if (authStatus === 'configuring') {
    return <Loading />;
  }

  if (authStatus !== 'authenticated') {
    return null;
  }

  if (hasSubscription === null) {
    return <Loading />;
  }

  return <>{children}</>;
};

const AppContent: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showBetaOver, setShowBetaOver] = useState(false);

  useEffect(() => {
    configureAmplify();
    const checkAuthState = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulating a delay
      setIsLoading(false);
    };
    checkAuthState();

    // Check for the environment variable
    setShowBetaOver(process.env.REACT_APP_SHOW_BETA_OVER === 'true');
  }, []);

  if (isLoading) {
    return <CoolLoading />;
  }

  return (
    <Router>
      <Elements stripe={stripePromise}>
        <Routes>
          <Route path="/" element={showBetaOver ? <LandingPageBetaOver /> : <LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
          <Route path="/compare" element={<ProtectedRoute><ComparisonPage /></ProtectedRoute>} />
          <Route path="/header" element={<Header />} />
          <Route path="/bottomnavbar" element={<BottomNavbar />} />
          <Route path="/checkout" element={<StripeCheckout />} />
          <Route path="/payment-success" element={<SuccessPage />} /> {/* Use the existing SuccessPage */}
          
          {/* New routes */}
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </Elements>
    </Router>
  );
};

const App: React.FC = () => {
  return (
    <Authenticator.Provider>
      <AppContent />
    </Authenticator.Provider>
  );
};

export default App;