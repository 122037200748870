import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleTitleClick = () => {
    if (location.pathname === '/home') {
      window.location.reload();
    } else {
      navigate('/home');
    }
  };

  // Use require with a path relative to the src directory
  const carbonFibreImg = require('../../assets/carbonfibre.jpg');

  return (
    <header 
      className="fixed top-0 left-0 right-0 bg-gray-900 p-1 flex justify-center items-center border-b-2 border-green-500 shadow-lg z-50 contrast-130"
    >
      {/* Background overlay similar to bottom navbar */}
      <div 
        className="absolute inset-0 bg-carbonfibre-header pointer-events-none"
        style={{ 
          '--carbonfibre-bg': `url(${carbonFibreImg})`,
          'backgroundBlendMode': 'screen',  // Change to lighten for a more pronounced effect
          'backgroundColor': 'rgba(10, 10, 10, 0.9)',  // Lighten the background even more
        } as React.CSSProperties}
      ></div>

      <motion.h1
        onClick={handleTitleClick}
        className="relative z-10 text-2xl font-bold text-green-500 cursor-pointer shadow-title-green"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, ease: 'easeOut' }}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Scratch Maestro Pro
      </motion.h1>
    </header>
  );
};

export default Header;
