import React, { useState, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { createCheckoutSession } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';
import { checkSubscriptionStatus } from '../../services/apiService';

const StripeCheckout: React.FC = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkSubscriptionAndInitiate = async () => {
      setLoading(true);
      setError(null);

      if (!user?.username) {
        setError("User information is not available. Please try logging in again.");
        setLoading(false);
        return;
      }

      try {
        const subscriptionStatus = await checkSubscriptionStatus(user.username);
        if (subscriptionStatus === true) {
          navigate('/home');
          return;
        }

        // Only initiate checkout if user doesn't have an active subscription
        const sessionUrl = await createCheckoutSession(user.username);
        window.location.href = sessionUrl;
      } catch (err) {
        console.error('Error:', err);
        setError("Failed to process your request. Please try again later.");
        setLoading(false);
      }
    };

    checkSubscriptionAndInitiate();
  }, [user, navigate]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <Loader2 className="w-16 h-16 mb-4 animate-spin text-neon-green" />
          <h2 className="text-2xl font-bold mb-2">Preparing Your Checkout</h2>
          <p className="text-gray-400">Just a moment while we set things up...</p>
        </motion.div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <p className="text-red-500 text-xl mb-4">{error}</p>
          <button 
            onClick={() => navigate('/home')} 
            className="bg-neon-green text-gray-900 px-6 py-2 rounded-full font-bold hover:bg-green-400 transition-colors duration-300"
          >
            Return to Home
          </button>
        </motion.div>
      </div>
    );
  }

  return null;
};

export default StripeCheckout;
