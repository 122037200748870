import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircle } from 'lucide-react';

export const SuccessPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to home page after a short delay
    const timer = setTimeout(() => navigate('/home'), 3000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-900 to-gray-800">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center"
      >
        <CheckCircle className="w-16 h-16 text-neon-green mb-4 mx-auto" />
        <h1 className="text-2xl font-bold text-white mb-2">Thank you for your subscription!</h1>
        <p className="text-lg text-gray-300">
          Your subscription has been confirmed. You will be redirected to the home page shortly.
        </p>
      </motion.div>
    </div>
  );
};

export default SuccessPage;
