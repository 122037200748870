import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import BottomNavbar from '../components/layout/BottomNavbar';
import { LotteryData, Game } from '../types/types';
import { ScratchCard } from '../components/game/ScratchCard';
import { GameCarousel } from '../components/game/GameCarousel';
import GameModal from '../components/game/GameModal';
import ResponsibleGamblingAccordion from '../components/common/ResponsibleGamblingAccordion';

const LazyResponsibleGamblingAccordion = React.lazy(() => import('../components/common/ResponsibleGamblingAccordion'));

// In your JSX
<React.Suspense fallback={<div>Loading...</div>}>
  <LazyResponsibleGamblingAccordion />
</React.Suspense>

export const HomePage: React.FC = () => {
  const [lotteryData, setLotteryData] = useState<LotteryData | null>(null);
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [customStyles, setCustomStyles] = useState<{ border: string; shadow: string }>({
    border: '',
    shadow: ''
  });
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedState, setSelectedState] = useState<string>(localStorage.getItem('selectedState') || 'tx');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const selectedState = localStorage.getItem('selectedState') || 'tx';
        const response = await fetch(`https://api.scratchmaestro.com/lottery-data/${selectedState}`);
        const data = await response.json();
        const transformedData: LotteryData = {
          lastUpdated: new Date().toISOString(),
          bestCurrentTicket: data.bestCurrentTicket,
          topChoices: data.topChoices,
          bottomChoices: data.bottomChoices,
          allGames: data.allGames,
          email: 'example@example.com'
        };
        setLotteryData(transformedData);
      } catch (error) {
        console.error('Error fetching lottery data:', error);
      }
    };

    fetchData();

    const handleStorageChange = () => {
      const newState = localStorage.getItem('selectedState') || 'tx';
      setSelectedState(newState);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [selectedState]);

  useEffect(() => {
    // Update the date every day at midnight
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 86400000); // 24 hours in milliseconds

    return () => clearInterval(timer);
  }, []);

  const handleCardClick = (game: Game, mode: "top" | "danger") => {
    setSelectedGame(game);
    setIsModalOpen(true);

    if (mode === "top") {
      setCustomStyles({ border: "border-yellow-400", shadow: "shadow-yellow-500/50" });
    } else if (mode === "danger") {
      setCustomStyles({ border: "border-red-400", shadow: "shadow-red-500/50" });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedGame(null);
  };

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header />
      <main className="pt-6 pb-20">
        {lotteryData && (
          <>
            <section className="mb-2 relative">
              <div className="flex justify-between items-center mb-3">
                <h2 className="frosted-glass w-full max-w-3xl text-center text-2xl font-bold text-green-500 mx-1">Today's Best Bet</h2>
                <div className="frosted-glass px-3 py-1 rounded-full text-yellow-400 font-bold">
                  {selectedState.toUpperCase()}
                </div>
              </div>
              <div className="frosted-glass bg-gray-800 p-4 rounded-lg">
                {lotteryData.bestCurrentTicket ? (
                  <div className="p-4 rounded-lg border-2 border-green-500 shadow-lg relative"
                       style={{ boxShadow: '0px 2px 10px 0px rgba(0, 255, 0, 0.2), 0px 5px 9px 0px rgba(0, 255, 0, 0.2)' }}>
                    <ScratchCard game={lotteryData.bestCurrentTicket} />
                  </div>
                ) : (
                  <p>No best current ticket available</p>
                )}
              </div>
            </section>

            <GameCarousel 
              key="topChoices" 
              title={<span className="text-yellow-400">TOP CHOICES</span>} 
              games={lotteryData.topChoices} 
              onCardClick={(game) => handleCardClick(game, "top")}
              customStyles={{ 
                border: "border-yellow-400",
                shadow: "shadow-yellow-500/50"
              }}
            />

            <GameCarousel 
              key="dangerZone" 
              title="DANGER ZONE" 
              games={lotteryData.bottomChoices} 
              isDangerZone={true}
              onCardClick={(game) => handleCardClick(game, "danger")}
              customStyles={{ 
                border: "border-red-500",
                shadow: "shadow-red-500/50"
              }}
            />

            <GameModal 
              game={selectedGame} 
              isOpen={isModalOpen} 
              onClose={handleCloseModal} 
              customStyles={customStyles}
            />

            <div className="text-center text-yellow-400 font-bold mt-2 mb-0">
              Last Updated: {formatDate(currentDate)}
            </div>

            <section className="py-0 md:py-4 bg-gray-900">
              <div className="container mx-auto px-11 py-1" >
                <ResponsibleGamblingAccordion key="gamblingAccordion" />
              </div>
            </section>
          </>
        )}
      </main>
      <BottomNavbar />
    </div>
  );
};

export default HomePage;