import React, { useEffect, useState } from 'react';
import { Authenticator, ThemeProvider, useAuthenticator, AuthenticatorProps } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { authenticatedFetch } from '../utils/secureapi';

import { configureAmplify } from '../amplifyConfig';
import { darkTheme } from '../styles/amplifyTheme';

// Add this at the top of your file
const isBetaOver = process.env.REACT_APP_BETA_OVER === 'true';

// Component for the login page
const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { authStatus, user } = useAuthenticator((context) => [context.authStatus, context.user]);
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);

  // Configure Amplify on component mount
  useEffect(() => {
    configureAmplify();
  }, []);

  // Redirect to home page if user is authenticated
  useEffect(() => {
    if (authStatus === 'authenticated' && user) {
      navigate('/home');
    }
  }, [authStatus, user, navigate]);

  // Fetch user attributes on component mount
  useEffect(() => {
    const getUserEmail = async () => {
      if (authStatus === 'authenticated') {
        try {
          const attributes = await fetchUserAttributes();
          setUserEmail(attributes.email);
        } catch (error) {
          console.error("Error fetching user attributes:", error);
        }
      }
    };

    getUserEmail();
  }, [authStatus]);

  // Define authenticator props based on beta status
  const authenticatorProps: AuthenticatorProps = {
    socialProviders: [],
    // Disable sign up if beta is over
    hideSignUp: isBetaOver,
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900">
        <motion.div
          className="flex flex-col items-center space-y-8 p-8 bg-gray-800 rounded-lg shadow-2xl"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        >
          {/* Animated title */}
          <motion.h1
            className="text-4xl font-extrabold text-neon-green text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            Welcome to Scratch Maestro Pro
          </motion.h1>

          {isBetaOver && (
            <motion.p
              className="text-yellow-500 text-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              New sign-ups are currently closed. Please check back later!
            </motion.p>
          )}

          <Authenticator {...authenticatorProps}>
            {({ signOut }) => (
              <AuthenticatedContent username={userEmail} onSignOut={signOut} />
            )}
          </Authenticator>
        </motion.div>
      </div>
    </ThemeProvider>
  );
};

// Component for authenticated user content
const AuthenticatedContent: React.FC<{ username?: string; onSignOut?: () => void }> = ({ username, onSignOut }) => {
  console.log("Received username:", username);

  // Function to truncate email and capitalize the first letter
  const truncateEmail = (email: string = '') => {
    const username = email.split('@')[0];
    return username.charAt(0).toUpperCase() + username.slice(1);
  };

  // Function to handle Stripe Customer Portal redirect
  const handleManageBilling = async () => {
    try {
      const { url } = await authenticatedFetch('/create-customer-portal-session', {
        method: 'POST',
        body: JSON.stringify({ customer: username }),
      });
      window.location.href = url;
    } catch (error) {
      console.error('Error creating customer portal session:', error);
    }
  };

  return (
    <main className="flex flex-col items-center space-y-4">
      <h2 className="text-xl text-green-500">{truncateEmail(username)}</h2>
      <Link
        to="/home"
        className="bg-neon-green text-gray-900 py-2 px-6 rounded-full text-lg font-bold shadow-lg hover:shadow-neon-green/50 transition-all duration-300"
      >
        Go to Dashboard
      </Link>
      <button
        onClick={handleManageBilling}
        className="bg-blue-500 text-white py-2 px-6 rounded-full text-lg font-bold shadow-lg hover:bg-blue-600 transition-all duration-300"
      >
        Manage Billing
      </button>
    </main>
  );
};

export default LoginPage;