// src/components/common/CoolLoading.tsx
import React from 'react';
import { motion } from 'framer-motion';

export const CoolLoading: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900">
      <motion.div
        className="flex flex-col items-center space-y-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        <div className="flex space-x-4">
          {['#10B981', '#FBBF24', '#EF4444'].map((color, index) => (
            <motion.div
              key={index}
              className="w-12 h-12 rounded-full shadow-2xl"
              style={{ backgroundColor: color }}
              animate={{
                y: [0, -20, 0],
                scale: [1, 1.3, 1],
                rotate: [0, 360, 0],
                boxShadow: [
                  `0 0 0px ${color}`,
                  `0 0 30px ${color}`,
                  `0 0 0px ${color}`
                ],
              }}
              transition={{
                repeat: Infinity,
                duration: 1.5,
                ease: 'easeInOut',
                delay: index * 0.2,
              }}
            />
          ))}
        </div>
        <motion.p
          className="mt-8 text-3xl font-extrabold text-neon-green text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          Scratching your ticket...
        </motion.p>
      </motion.div>
    </div>
  );
};

export default CoolLoading;
