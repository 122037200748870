import React from 'react';
import { motion } from 'framer-motion';
import { Shield, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleClose}>
      <motion.div
        className="bg-gray-900 text-white p-8 rounded-lg max-w-3xl max-h-[90vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.3 }}
      >
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 p-2 text-white hover:text-neon-green"
        >
          <X size={24} />
        </button>
        <h1 className="text-4xl font-bold mb-8 text-center text-neon-green flex items-center justify-center">
          <Shield className="mr-2" size={36} />
          Privacy Policy
        </h1>
        
        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">1. Information We Collect</h2>
            <p>We collect information you provide directly to us, such as when you create an account, subscribe to our service, or contact us for support. This may include your name, email address, and payment information.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">2. How We Use Your Information</h2>
            <p>We use the information we collect to provide, maintain, and improve our services, process transactions, send you technical notices and support messages, and respond to your comments and questions.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">3. Information Sharing and Security</h2>
            <p>We do not share your personal information with third parties except as described in this policy. We take reasonable measures to help protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">4. Your Choices</h2>
            <p>You may update, correct, or delete your account information at any time by logging into your account or by contacting us. You may also opt out of receiving promotional communications from us by following the instructions in those messages.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">5. Changes to This Policy</h2>
            <p>We may change this privacy policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, provide you with additional notice.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-neon-green">6. Contact Us</h2>
            <p>If you have any questions about this privacy policy, please contact us at: privacy@scratchmaestro.com</p>
          </section>
        </div>
      </motion.div>
    </div>
  );
};

export default PrivacyPolicy;