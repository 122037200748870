import { Theme } from '@aws-amplify/ui-react';

export const darkTheme: Theme = {
  name: 'dark-theme',
  tokens: {
    colors: {
      background: {
        primary: { value: '#1a202c' }, // Dark background color
        secondary: { value: '#2d3748' }, // Slightly lighter dark color
      },
      font: {
        primary: { value: '#e2e8f0' }, // Light font color
        secondary: { value: '#a0aec0' }, // Slightly darker font color
      },
      border: {
        primary: { value: '#4a5568' }, // Border color
      },
      brand: {
        primary: { value: '#10B981' }, // Your brand color
      },
    },
    components: {
      button: {
        primary: {
          backgroundColor: { value: '#10B981' },
          color: { value: '#1a202c' },
        },
      },
    },
  },
};