import React from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Game } from '../../types/types';
import { GameCard } from './GameCard';

interface CustomArrowProps {
  className?: string;
  onClick?: () => void;
}

const CustomArrow: React.FC<CustomArrowProps> = ({ className, onClick }) => (
  <div
    className={`${className} !bg-gray-600 !text-white rounded-full z-10`}
    style={{ display: 'block' }}
    onClick={onClick}
  />
);

interface GameCarouselProps {
  title: React.ReactNode; 
  games: Game[];
  isDangerZone?: boolean;
  onCardClick?: (game: Game) => void;
  customStyles?: {
    border?: string;
    shadow?: string;
  };
}

export const GameCarousel: React.FC<GameCarouselProps> = ({ title, games = [], isDangerZone = false, onCardClick, customStyles }) => {
  // Sort games based on valueScore for display, either ascending or descending
  const sortedGames = games.sort((a, b) => {
    if (a.valueScore === undefined || b.valueScore === undefined) return 0;
    return isDangerZone ? a.valueScore - b.valueScore : b.valueScore - a.valueScore;
  });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <motion.div 
      className={`w-full max-w-5xl mt-4 main-container ${customStyles?.border || ''} ${customStyles?.shadow || ''} relative z-0`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h3 className={`frosted-glass text-xl font-bold text-center mb-4 ${isDangerZone ? 'text-red-500' : 'text-green-500'}`}>
        {title}
      </h3>
      <Slider {...settings}>
        {sortedGames.slice(0, 10).map((game, index) => (
          <div 
            key={game.game_number} 
            className="px-2 py-4 flex justify-center items-stretch"
            onClick={() => onCardClick && onCardClick(game)}
          >
            <GameCard 
              game={game} 
              isDangerZone={isDangerZone} 
              number={index + 1}
            />
          </div>
        ))}
      </Slider>
    </motion.div>
  );
};

export default GameCarousel;

