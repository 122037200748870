import React, { useState, useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';
import {
  Check,
  Activity,
  TrendingUp,
  Shield,
  Star,
  MapPin,
  Zap,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Header from '../components/layout/Header';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import axios from 'axios';
import ResponsibleGamblingAccordion from '../components/common/ResponsibleGamblingAccordion';

// AnimatedButton component with directional cue
const AnimatedButton: React.FC<{
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}> = ({ children, className, onClick, type, disabled }) => (
  <motion.button
    className={`${className} transition duration-300`}
    whileHover={{ scale: 1.05, boxShadow: '0 0 15px #ff0000' }}
    whileTap={{ scale: 0.95 }}
    onClick={onClick}
    type={type}
    disabled={disabled}
  >
    {children}
  </motion.button>
);

// Feature interface
interface Feature {
  icon: React.ElementType;
  title: string;
  description: string;
}

// Features data with simplified messaging
const features: Feature[] = [
  {
    icon: Zap,
    title: 'Instantly Boost Your Odds',
    description: 'Maximize your chances of winning big prizes effortlessly.',
  },
  {
    icon: Activity,
    title: 'Real-Time Data Everywhere',
    description:
      'Access up-to-date scratch-off game data across 19 states.',
  },
  {
    icon: Shield,
    title: 'Save Money',
    description: 'Avoid low-value tickets and make every dollar count.',
  },
  {
    icon: Star,
    title: 'Personalized Picks',
    description:
      'Get game recommendations tailored just for you.',
  },
  {
    icon: MapPin,
    title: 'Easy Mobile Access',
    description:
      'Save to your home screen and access winning strategies anytime.',
  },
  {
    icon: TrendingUp,
    title: 'Join Winners Community',
    description:
      'Be part of a thriving group celebrating wins together.',
  },
];

// Testimonials data with user statistics
interface Testimonial {
  text: string;
  author: string;
  
}

const testimonials: Testimonial[] = [
  {
    text: "I couldn't believe it when I finally won! Scratch Maestro Pro made it possible.",
    author: 'Emily R., California',
    
  },
  {
    text: 'This app is worth every penny. My winnings have tripled!',
    author: 'David S., New York',
   
  },
];

// FAQs data
interface FAQ {
  question: string;
  answer: string;
}

const faqs: FAQ[] = [
  {
    question: 'Is Scratch Maestro Pro easy to use?',
    answer:
      'Absolutely! Our intuitive design ensures a smooth experience for users of all tech levels.',
  },
  {
    question: 'How does the money-back guarantee work?',
    answer:
      "If you're not satisfied within 30 days, contact us for a full refund—no questions asked.",
  },
  {
    question: 'Can I use it in my state?',
    answer:
      'We currently support 19 states, and we\'re expanding rapidly. Check our list for your state.',
  },
  {
    question: 'Is my information secure?',
    answer:
      'Yes, we use advanced encryption to protect your personal data and transactions.',
  },
  {
    question: 'How often is the data updated?',
    answer:
      'Our data is updated in real-time to provide you with the most accurate information.',
  },
];

// Main LandingPage component
const ScratchMaestroAnimatedLanding: React.FC = () => {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator((context) => [context.authStatus, context.user]);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [userState, setUserState] = useState<string>('');

  const flagCardRef = useRef(null);
  const isInView = useInView(flagCardRef, { once: false, amount: 0.3 });
  const flagCardAnimation = useAnimation();
  const flagTextAnimation = useAnimation();

  useEffect(() => {
    const getUserState = async () => {
      try {
        const response = await axios.get('https://ipinfo.io', {
          headers: {
            'Authorization': 'Bearer b6d5af106c9482'
          }
        });
        const state = response.data.region;
        setUserState(state);
      } catch (error) {
        console.error('Error fetching user location:', error);
        setUserState('Lottery Player'); // Default fallback
      }
    };

    getUserState();
  }, []);

  // Handle animations when flag card is in view
  useEffect(() => {
    if (isInView) {
      flagCardAnimation.start({ y: 0, opacity: 1 });
      flagTextAnimation.start({ scale: 1, opacity: 1 });
    }
  }, [isInView, flagCardAnimation, flagTextAnimation]);

  // Scroll to section function
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setIsMenuOpen(false);
    }
  };

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMenuOpen]);

  // Handle subscribe button click
  const handleSubscribeClick = () => {
    if (authStatus === 'authenticated') {
      navigate('/checkout');
    } else {
      navigate('/signup');
    }
  };

  // Handle login button click
  const handleLoginClick = () => {
    if (authStatus === 'authenticated') {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  };

   // List of supported states
  const supportedStates = [
    'Arizona', 'California', 'District of Columbia', 'Florida',
    'Illinois', 'Kansas', 'Kentucky', 'Maryland',
    'Missouri', 'Mississippi', 'North Carolina', 'New Mexico',
    'New York', 'Ohio', 'Oklahoma', 'Oregon',
    'Texas', 'Virginia', 'Washington'
  ];

  return (
    <div className="bg-gray-900 text-white min-h-screen relative">
      <Header />

      {/* Move Login/Dashboard Button to the header */}
      <div className="absolute top-0 right-0 z-50">
        <AnimatedButton
          className="bg-red-500 text-white py-0 px-1 rounded-full text-sm font-bold shadow-lg hover:shadow-red-500/50 transition-all duration-300"
          onClick={handleLoginClick}
        >
          {authStatus === 'authenticated' ? 'Go to Dashboard' : 'Login'}
        </AnimatedButton>
      </div>

      <main>
        {/* Updated Main Hero Content */}
        <section className="py-12 md:py-20">
          <div className="container mx-auto px-4 flex flex-col items-center min-h-[200px]">
            {/* Personalized Headline */}
            <motion.h1
              className="text-4xl md:text-6xl lg:text-7xl font-extrabold mb-4 text-center text-neon-green z-10 pt-10"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Hey {userState} Player, Unlock Winning Secrets Today!
            </motion.h1>

            <motion.p
              className="text-xl md:text-2xl font-bold lg:text-3xl mb-8 text-gray-300 text-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              Boost your scratch-off success using real-time data and powerful algorithms.
            </motion.p>

            {/* Hero Image with directional cue */}
            <motion.div
              className="my-8 relative"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.7, ease: 'easeOut', delay: 0.3 }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/620shots_so.png`}
                alt="Scratch Maestro Hero"
                className="w-full max-w-2xl mx-auto"
              />
            </motion.div>

            <AnimatedButton
              className="bg-red-500 text-white py-3 px-8 md:py-4 md:px-10 rounded-full text-xl md:text-2xl font-bold shadow-lg hover:shadow-red-500/50 transition-all duration-300 mb-8"
              onClick={() => scrollToSection('pricing')}
            >
              Start Winning Now!
            </AnimatedButton>

            {/* Add Supported States Section */}
            <div className="mt-8 text-center">
              <h3 className="text-xl md:text-2xl font-bold mb-4 text-neon-green">We Serve 19 States:</h3>
              <div className="flex flex-wrap justify-center gap-2">
                {supportedStates.map((state, index) => (
                  <span key={index} className="bg-gray-700 px-3 py-1 rounded-full text-sm font-bold">
                    {state}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="py-12 md:py-20 bg-gray-800">
          <div className="container mx-auto px-4">
            <motion.h3
              className="text-2xl md:text-4xl font-bold mb-8 md:mb-10 text-center text-neon-green"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              Why Thousands Choose Scratch Maestro Pro
            </motion.h3>
            {/* User Statistics */}
            <p className="text-center text-lg md:text-xl font-bold mb-6 text-gray-300">
              Join <span className="text-yellow-500">Thousands</span> of users boosting their odds!
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 md:gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  className="bg-gray-700 p-4 md:p-6 rounded-lg mb-10"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                >
                  <feature.icon size={32} className="text-neon-green mb-3 md:mb-14" />
                  <h4 className="text-xl md:text-2xl font-bold mb-2">{feature.title}</h4>
                  <p className="text-sm md:text-lg font-bold">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Product Shot 3 Section */}
        <section className="py-12 md:py-20 bg-gray-900">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="text-center"
            >
              <div className="max-w-md mx-auto"> {/* Added container with max width */}
                <img 
                  src={`${process.env.PUBLIC_URL}/productshot3.png`} 
                  alt="Scratch Maestro Pro Product Shot" 
                  className="w-full h-auto rounded-lg shadow-2xl"
                />
              </div>
            </motion.div>
          </div>
        </section>

        {/* How to Use Section with simplified content */}
        <section id="how-to-use" className="py-12 md:py-20 bg-gray-900">
          <div className="container mx-auto px-4 text-center">
            <motion.h3
              className="text-2xl md:text-4xl font-bold mb-8 md:mb-10 text-neon-green"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              Get Started in 3 Easy Steps
            </motion.h3>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-gray-700 p-6 rounded-lg">
                <h4 className="text-lg md:text-xl font-bold mb-2">1. Sign Up</h4>
                <p className="text-sm md:text-base font-bold">
                  Create your account in seconds to unlock all features.
                </p>
              </div>
              <div className="bg-gray-700 p-6 rounded-lg">
                <h4 className="text-lg md:text-xl font-bold mb-2">2. Choose Your State</h4>
                <p className="text-sm md:text-base font-bold">
                  Select from 19 states on the profile page to get tailored data.
                </p>
              </div>
              <div className="bg-gray-700 p-6 rounded-lg">
                <h4 className="text-lg md:text-xl font-bold mb-2">3. Start Winning</h4>
                <p className="text-sm md:text-base font-bold">
                  Use our insights to play smarter and win more.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section id="testimonials" className="py-12 md:py-20 mb-8 bg-gray-800">
          <div className="container mx-auto px-4">
            <motion.h3
              className="text-2xl md:text-4xl font-bold mb-6 md:mb-8 text-center text-neon-green"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              Success Stories
            </motion.h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {testimonials.map((testimonial, index) => (
                <motion.div
                  key={index}
                  className="bg-gray-700 p-6 rounded-lg hover:shadow-lg transition-shadow duration-300 relative"
                  initial={{ opacity: 0, scale: 0.8 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  whileHover={{ y: -5 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  viewport={{ once: true }}
                >
                  <p className="text-lg mb-4 font-bold">"{testimonial.text}"</p>
                  <div className="flex items-center">
                    <p className="font-bold">{testimonial.author}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Updated Pricing Section with simplified messaging and value stack */}
        <section id="pricing" className="py-12 md:py-20 bg-gray-900">
          <div className="container mx-auto px-4 text-center">
            <motion.h3
              className="text-2xl md:text-4xl font-bold mb-6 md:mb-8 text-neon-green"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              Choose Your Plan
            </motion.h3>

            {/* Add Open Beta Announcement */}
            <motion.div
              className="mb-8 md:mb-12 text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <p className="text-lg md:text-xl font-bold text-yellow-500">
                Exciting News! We are currently in Open Beta.
              </p>
              <p className="text-sm md:text-base font-bold text-gray-300 mt-2">
                Sign up now to lock in our low introductory price for the life of your membership.
                As prices may increase after the beta phase, this is the perfect time to join and secure your grandfathered rate!
              </p>
            </motion.div>

            <motion.div
              className="bg-gray-800 p-6 md:p-8 rounded-lg max-w-2xl mx-auto"
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <h4 className="text-2xl md:text-3xl font-bold mb-4">Scratch Maestro Pro</h4>
              <p className="text-3xl md:text-5xl font-bold mb-4 text-neon-green">
                $7.77<span className="text-xl md:text-2xl font-normal text-white">/month</span>
              </p>
              {/* Anchoring Effect */}
              <p className="text-base md:text-lg font-bold mb-4 text-gray-400 line-through">
                Originally $15.99/month
              </p>
              {/* Risk Reversal */}
              <p className="text-sm md:text-base font-bold mb-4 text-yellow-500">
                30-Day Money-Back Guarantee
              </p>
              {/* Value Stack */}
              <ul className="text-left mb-8">
                {[
                  'Instantly boost your winning odds',
                  'Access real-time data in 19 states',
                  'Personalized game recommendations',
                  'Avoid low-value tickets',
                  'Join a community of winners',
                  '24/7 customer support',
                  'Easy-to-use mobile access',
                  'Regular updates and new features',
                ].map((feature, index) => (
                  <motion.li
                    key={index}
                    className="mb-2 flex items-center text-sm md:text-base font-bold"
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                    viewport={{ once: true }}
                  >
                    <Check className="text-neon-green mr-2 flex-shrink-0" /> {feature}
                  </motion.li>
                ))}
              </ul>
              <AnimatedButton
                className="bg-red-500 text-white py-2 px-6 md:py-3 md:px-8 rounded-full text-lg md:text-xl font-bold"
                onClick={handleSubscribeClick}
              >
                {authStatus === 'authenticated' ? 'Subscribe Now' : 'Sign Up to Subscribe'}
              </AnimatedButton>
              {/* Additional Trust Badges */}
              <div className="mt-6 flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/SSL-PNG-Pic-2421246430.png`}
                  alt="Secure Payment"
                  className="w-16 h-16 mr-4"
                />
                <p className="text-sm md:text-base font-bold">Secure Payment Guaranteed</p>
              </div>
            </motion.div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-12 md:py-20 bg-gray-800">
          <div className="container mx-auto px-4">
            <motion.h3
              className="text-2xl md:text-4xl font-bold mb-6 md:mb-8 text-center text-neon-green"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              Got Questions? We Have Answers!
            </motion.h3>
            <div className="frosted-glass grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
              {faqs.map((faq, index) => (
                <motion.div
                  key={index}
                  className="bg-gray-700 p-6 frosted-glass rounded-lg"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                >
                  <h4 className="text-lg md:text-xl font-bold mb-2">{faq.question}</h4>
                  <p className="text-sm md:text-base font-bold">{faq.answer}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Responsible Gambling Section */}
        <section className="py-12 md:py-20 bg-gray-900">
          <div className="container mx-auto px-4">
            <ResponsibleGamblingAccordion />
          </div>
        </section>

        {/* Community Invitation */}
        <section className="py-12 md:py-20 bg-gray-800">
          <div className="container mx-auto px-4 text-center">
            <h3 className="text-2xl md:text-4xl font-bold mb-6 text-neon-green">
              Join Our Community of Winners!
            </h3>
            <p className="text-sm md:text-base font-bold mb-8">
              Follow us on social media and share your success stories.
            </p>
            {/* Social Media Icons */}
            <div className="flex justify-center space-x-6">
              <a href="https://www.facebook.com/scratchmaestro" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-500">
                <FaFacebook size={32} />
              </a>
              <a href="https://twitter.com/ScratchMaestro" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400">
                <FaTwitter size={32} />
              </a>
              <a href="https://www.instagram.com/scratchmaestro" target="_blank" rel="noopener noreferrer" className="text-white hover:text-pink-500">
                <FaInstagram size={32} />
              </a>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 py-8">
  <div className="container mx-auto px-4 text-center">
    <p className="text-sm text-gray-400">
      © {new Date().getFullYear()} Scratch Maestro Pro. All rights reserved.
    </p>
    <p className="text-sm text-gray-400 mt-2">
      Disclaimer: Scratch Maestro is not affiliated with or endorsed by any official lottery organization.
    </p>
    {/* Additional Links */}
    <div className="mt-4 flex justify-center space-x-4">
      <a href="/privacy" className="text-sm text-gray-400 hover:text-neon-green">
        Privacy Policy
      </a>
      <a href="/terms" className="text-sm text-gray-400 hover:text-neon-green">
        Terms of Service
      </a>
      <a href="/contact" className="text-sm text-gray-400 hover:text-neon-green">
        Contact Us
      </a>
    </div>
  </div>
</footer>
    </div>
  );
};

export default ScratchMaestroAnimatedLanding;
