import React, { useEffect, useState, useCallback } from 'react';
import { Game } from '../../types/types';
import { motion, AnimatePresence } from 'framer-motion';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CountUp from 'react-countup';
import { Flame, Info } from 'lucide-react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

interface ScratchCardProps {
  game: Game;
  customStyles?: {
    border?: string;
    shadow?: string;
  };
}

export const ScratchCard: React.FC<ScratchCardProps> = React.memo(({ game, customStyles = {} }) => {
  const [donutValue, setDonutValue] = useState(0);
  const [roiPercentage, setRoiPercentage] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isInterpretationExpanded, setIsInterpretationExpanded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setDonutValue((prev) => {
        const newValue = prev + 1;
        if (newValue >= game.compositeScore) {
          clearInterval(interval);
          return game.compositeScore;
        }
        return newValue;
      });
    }, 20);
    return () => clearInterval(interval);
  }, [game.compositeScore]);

  useEffect(() => {
    setRoiPercentage(game.ROI);
  }, [game.ROI]);

  const interpolateColor = useCallback((value: number) => {
    const red = Math.round(255 * (1 - value / 100));
    const green = Math.round(255 * (value / 100));
    return `rgb(${red},${green},0)`;
  }, []);

  const formatCurrentOdds = useCallback((odds: string | React.ReactNode): string => {
    if (!odds) return 'N/A'; // Handle undefined or null odds
    const oddsString = String(odds);
    if (oddsString.startsWith('1 in ')) {
      const numericPart = oddsString.split('1 in ')[1];
      return `1 in ${parseFloat(numericPart).toFixed(2)}`;
    }
    const numericOdds = parseFloat(oddsString.replace(/[^0-9.-]+/g, ""));
    if (isNaN(numericOdds) || numericOdds <= 0) return 'N/A';
    return `1 in ${numericOdds.toFixed(2)}`;
  }, []);

  const handleTooltipToggle = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setShowTooltip((prev) => !prev);
  }, []);

  const handleCloseTooltip = useCallback(() => {
    setShowTooltip(false);
  }, []);

  useEffect(() => {
    if (showTooltip) {
      document.addEventListener('click', handleCloseTooltip);
    }
    return () => {
      document.removeEventListener('click', handleCloseTooltip);
    };
  }, [showTooltip, handleCloseTooltip]);

  return (
    <div 
      className={`bg-gray-900 p-6 rounded-lg relative text-white ${customStyles.border || 'shadow-green-700/100'}`}
    >
      <h3 className="text-3xl font-bold mb-4 flex items-center justify-between text-green-500">
        {game.game_name}
        <motion.div 
          className="absolute top-2 right-2"
          initial={{ scale: 1 }}
          animate={{ scale: [1, 1.2, 1], rotate: [0, 10, -10, 0] }}
          transition={{
            duration: 0.2,
            repeat: Infinity,
            repeatType: 'mirror',
          }}
        >
          <Flame size={18} color="red" fill="orange" />
        </motion.div>
      </h3>

      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="flex flex-col items-center">
          <CountUp 
            start={game.ticket_price}
            end={game.ticket_price} 
            duration={2} 
            prefix="$" 
            className="text-2xl font-semibold text-green-500"
          />
          <p className="text-gray-400 text-sm">Ticket Price</p>
        </div>
        <div className="flex flex-col items-center">
          <CountUp 
            start={0}
            end={game.winPotential} 
            duration={2} 
            decimals={2}
            className="text-2xl font-semibold text-yellow-500"
          />
          <p className="text-gray-400 text-sm">Win Potential</p>
        </div>
      </div>

      <div className="mb-4 flex flex-col items-center relative">
        <div className="w-40 h-40 mb-4">
          <CircularProgressbar
            value={donutValue}
            maxValue={100}
            text={`${donutValue.toFixed(1)}`}
            styles={buildStyles({
              textSize: '24px',
              textColor: interpolateColor(donutValue),
              pathColor: interpolateColor(donutValue),
              trailColor: "#2c2c2c",
              pathTransitionDuration: 0.5,
              strokeLinecap: 'butt',
            })}
            strokeWidth={12}
          />
        </div>
        <div className="flex items-center justify-center w-full">
          <p className="text-gray-400 text-sm mr-2">Composite Score (out of 100)</p>
          <motion.button
            onClick={handleTooltipToggle}
            className="text-gray-400 hover:text-gray-300 focus:outline-none relative"
            initial={{ scale: 1 }}
            animate={{
              scale: [1, 1.2, 1],
              transition: {
                duration: 1,
                times: [0, 0.5, 1],
                repeat: 1,
                repeatDelay: 1
              }
            }}
          >
            <motion.div
              className="absolute inset-0 bg-yellow-300 rounded-full opacity-50"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{
                scale: [0.8, 1.2, 0.8],
                opacity: [0, 0.7, 0],
                transition: {
                  duration: 2,
                  repeat: 0,
                  repeatDelay: 1
                }
              }}
            />
            <Info size={16} className="relative z-10" />
          </motion.button>
        </div>
      </div>

      <div className="mb-4">
        <p className="text-center text-sm text-gray-400 mb-2">Return on Investment (ROI)</p>
        <div className="relative h-4 bg-gray-700 rounded-full overflow-hidden">
          <motion.div
            className="h-full"
            style={{
              background: `linear-gradient(to right, ${interpolateColor(0)}, ${interpolateColor(33)}, ${interpolateColor(66)}, ${interpolateColor(100)})`,
            }}
            initial={{ width: '0%' }}
            animate={{ width: `${roiPercentage}%` }}
            transition={{ duration: 2, ease: "easeOut" }}
          />
        </div>
        <div className="flex justify-between text-xs text-gray-500 mt-1">
          <span>0%</span>
          <CountUp
            start={0}
            end={roiPercentage}
            duration={2}
            decimals={1}
            suffix="%"
            className="text-green-500 font-semibold"
          />
          <span>100%</span>
        </div>
      </div>

      <p className="text-sm text-gray-400">Current Odds: {formatCurrentOdds(game.currentOdds)}</p>

      <div className="mt-4 bg-gray-800 rounded-lg">
        <button 
          className="w-full p-4 flex justify-between items-center text-sm text-gray-200 font-semibold"
          onClick={(e) => {
            e.stopPropagation();
            setIsInterpretationExpanded(!isInterpretationExpanded);
          }}
        >
          Composite Score Interpretation
          {isInterpretationExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        <AnimatePresence>
          {isInterpretationExpanded && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="overflow-hidden"
            >
              <p className="p-4 text-sm text-gray-400">
                With a Win Potential {game.ROIInterpretation.split("with a Win Potential")[1].trim()}
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {showTooltip && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={handleCloseTooltip}
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="bg-gray-800 p-4 rounded-lg shadow-lg max-w-sm mx-4 text-white text-left"
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking on content
            >
              <h4 className="text-lg font-bold mb-2">Composite Score Explanation</h4>
              <p className="mb-4">The Composite Score is a comprehensive measure of a scratch-off ticket's value. It takes into account factors such as the ticket price, odds of winning, and potential return on investment. This score helps you quickly assess the overall attractiveness of a ticket, balancing various aspects to give you a clear picture of its value proposition.</p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default ScratchCard;