import { fetchAuthSession } from 'aws-amplify/auth';

const API_BASE_URL = 'https://api.scratchmaestro.com';

export async function authenticatedFetch(endpoint: string, options: RequestInit = {}) {
  try {
    const { tokens } = await fetchAuthSession();
    const token = tokens?.idToken?.toString();

    if (!token) {
      throw new Error('No authentication token available');
    }

    const headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      ...options,
      headers,
      credentials: 'include', // This line is important
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error in authenticatedFetch:', error);
    throw error;
  }
}