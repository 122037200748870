import { LotteryData, Game, UserData } from '../types/types';
import { authenticatedFetch } from '../utils/secureapi';

const API_BASE_URL = 'https://api.scratchmaestro.com';

if (!API_BASE_URL) {
  throw new Error('REACT_APP_API_URL is not defined in the environment');
}

// Fetch structured data
export const fetchStructuredData = async (state: string): Promise<LotteryData> => {
  return authenticatedFetch(`/lottery-data/${state}`);
};

// Get lottery data
export const getLotteryData = async (state: string): Promise<LotteryData> => {
  try {
    const data = await fetchStructuredData(state);
    return data;
  } catch (error) {
    console.error('Error fetching lottery data:', error);
    throw error;
  }
};

// Fetch games
export const fetchGames = async (state: string): Promise<{ allGames: Game[], topChoices: Game[], bottomChoices: Game[] }> => {
  return authenticatedFetch(`/lottery-data/${state}`);
};

// Fetch user data (mock function for now)
export const fetchUserData = async (userId: string): Promise<UserData> => {
  return authenticatedFetch(`/user/${userId}`);
};

// Check subscription status by username
export const checkSubscriptionStatus = async (userId: string): Promise<boolean> => {
  const data = await authenticatedFetch(`/subscription-status/${userId}`);
  return data.status === 'active';
};

// Check subscription status by Stripe session ID
export const checkSubscriptionStatusBySession = async (sessionId: string): Promise<boolean> => {
  try {
    const response = await fetch(`${API_BASE_URL}/subscription-status/session/${sessionId}`);
    if (!response.ok) {
      throw new Error('Failed to check subscription status');
    }
    const data = await response.json();
    return data.status === 'active';
  } catch (error) {
    console.error('Error checking subscription status:', error);
    throw error;
  }
};

// Create a subscription
export const createSubscription = async (userId: string, planId: string): Promise<void> => {
  return authenticatedFetch('/create-subscription', {
    method: 'POST',
    body: JSON.stringify({ userId, planId }),
  });
};

// Cancel a subscription
export const cancelSubscription = async (userId: string): Promise<void> => {
  return authenticatedFetch('/cancel-subscription', {
    method: 'POST',
    body: JSON.stringify({ userId }),
  });
};

// Create a checkout session
export const createCheckoutSession = async (userId: string): Promise<string> => {
  const data = await authenticatedFetch('/create-checkout-session', {
    method: 'POST',
    body: JSON.stringify({ userId }),
  });
  return data.url;
};

// Create a customer portal session
export const createCustomerPortalSession = async (username: string): Promise<string> => {
  const data = await authenticatedFetch('/create-customer-portal-session', {
    method: 'POST',
    body: JSON.stringify({ customer: username }),
  });
  return data.url;
};

const apiService = {
  getLotteryData,
  fetchGames,
  fetchUserData,
  checkSubscriptionStatus,
  createSubscription,
  cancelSubscription,
  createCheckoutSession,
  createCustomerPortalSession,
};

export default apiService;
