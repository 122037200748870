import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { User, MapPin, LogOut, Flame, DollarSign, Camera, Mail, FileText, Shield } from 'lucide-react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import { PiggyBank } from 'lucide-react';
//import { useNavigate } from 'react-router-dom';
import Header from '../components/layout/Header';
import BottomNavbar from '../components/layout/BottomNavbar';
import defaultAvatar from '../assets/defaultavatar.jpg';
import ResponsibleGamblingAccordion from '../components/common/ResponsibleGamblingAccordion';
//import SubscriptionStatusCheck from '../components/SubscriptionStatusCheck';
import { Button } from '../components/common/Button';
import { authenticatedFetch } from '../utils/secureapi';

// Define the structure for user data
interface UserData {
  email: string;
  profilePicture: string;
  streak: number;
  estimatedSavings: number;
  state: string;
  subStatus: string;
  initialLoginDate: string;
  selectedState: string;
}

const ProfilePage: React.FC = () => {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const [profile, setProfile] = useState<UserData>({
    email: '',
    profilePicture: defaultAvatar,
    streak: 0,
    estimatedSavings: 0,
    state: 'Texas',
    subStatus: '',
    initialLoginDate: '',
    selectedState: localStorage.getItem('selectedState') || 'tx',
  });

  // Load user data from Amplify and local storage
  const loadUserData = useCallback(async () => {
    try {
      const userAttributes = await fetchUserAttributes();
      
      // Get or set initial login date
      let initialLoginDate = localStorage.getItem('initialLoginDate');
      if (!initialLoginDate) {
        initialLoginDate = new Date().toISOString();
        localStorage.setItem('initialLoginDate', initialLoginDate);
      }

      const userData: UserData = {
        email: userAttributes.email || '',
        profilePicture: localStorage.getItem('profilePicture') || defaultAvatar,
        streak: parseInt(localStorage.getItem('streak') || '0'),
        estimatedSavings: calculateEstimatedSavings(initialLoginDate),
        state: localStorage.getItem('userState') || 'Texas',
        subStatus: userAttributes['custom:sub_status'] || 'No subscription',
        initialLoginDate,
        selectedState: localStorage.getItem('selectedState') || 'tx',
      };
      setProfile(userData);
      localStorage.setItem('userEmail', userAttributes.email || '');
    } catch (error) {
      console.error("Failed to load user data:", error);
    }
  }, []);

  useEffect(() => {
    loadUserData();
    // Set up an interval to update estimated savings every hour
    const intervalId = setInterval(() => {
      setProfile(prevProfile => ({
        ...prevProfile,
        estimatedSavings: calculateEstimatedSavings(prevProfile.initialLoginDate),
      }));
    }, 3600000); // 1 hour in milliseconds

    return () => clearInterval(intervalId);
  }, [loadUserData]);

  // Function to calculate estimated savings
  const calculateEstimatedSavings = (initialDate: string): number => {
    const start = new Date(initialDate);
    const now = new Date();
    const hoursElapsed = (now.getTime() - start.getTime()) / (1000 * 60 * 60);
    const weeksElapsed = hoursElapsed / (7 * 24);
    const savingsPerWeek = 25;
    return weeksElapsed * savingsPerWeek;
  };

  // Handle profile picture change
  const handleProfilePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        localStorage.setItem('profilePicture', base64String);
        setProfile(prev => ({ ...prev, profilePicture: base64String }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleManageBilling = async () => {
    try {
      const { url } = await authenticatedFetch('/create-customer-portal-session', {
        method: 'POST',
        body: JSON.stringify({ customer: profile.email }),
      });
      window.location.href = url;
    } catch (error) {
      console.error('Error creating customer portal session:', error);
      // Optionally, show an error message to the user
    }
  };

  const handleStateChange = (newState: string) => {
    localStorage.setItem('selectedState', newState);
    setProfile(prev => ({ ...prev, selectedState: newState, state: newState }));
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-900 to-gray-800">
      <Header />
      <main className="flex-grow pt-16 pb-20">
        <div className="container mx-auto px-4 py-8">
          {/* Profile Header */}
          <ProfileHeader profile={profile} onProfilePictureChange={handleProfilePictureChange} />

          {/* User Information */}
          <UserInformation profile={profile} onManageBilling={handleManageBilling} onStateChange={handleStateChange} />

          {/* Estimated Savings */}
          <EstimatedSavings estimatedSavings={profile.estimatedSavings} />

          {/* New section for additional links */}
          <AdditionalLinks />

          {/* Responsible Gambling Accordion */}
          <ResponsibleGamblingAccordion />

          {/* Sign Out Button */}
          <SignOutButton onSignOut={signOut} />
        </div>
      </main>
      <BottomNavbar />
    </div>
  );
};

// Profile Header Component
const ProfileHeader: React.FC<{ profile: UserData; onProfilePictureChange: (event: React.ChangeEvent<HTMLInputElement>) => void }> = ({ profile, onProfilePictureChange }) => {
  // Function to truncate email and capitalize the first letter
  const truncateEmail = (email: string) => {
    const username = email.split('@')[0];
    return username.charAt(0).toUpperCase() + username.slice(1);
  };

  return (
    <motion.div 
      className="relative flex flex-col items-center mb-8"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="relative mb-4">
        <motion.img 
          src={profile.profilePicture}
          alt="Profile"
          className="rounded-full w-24 h-24 object-cover mx-auto border-4 border-green-500"
          initial={{ boxShadow: '0 0 20px #00FF00' }}
          animate={{ boxShadow: ['0 0 20px #00FF00', '0 0 30px #00FF00', '0 0 20px #00FF00'] }}
          transition={{ duration: 2, repeat: Infinity }}
        />
        <label htmlFor="profile-picture-input" className="absolute bottom-0 right-0 bg-green-500 rounded-full p-2 cursor-pointer">
          <Camera size={16} color="white" />
        </label>
        <input 
          id="profile-picture-input" 
          type="file" 
          accept="image/*" 
          className="hidden" 
          onChange={onProfilePictureChange}
        />
      </div>
      <p className="text-lg text-white mb-2 font-bold">{truncateEmail(profile.email)}</p>
      <p className="text-lg text-green-500 font-bold">
        <Flame className="inline-block w-6 h-6 text-red-700" /> {profile.streak} day streak
      </p>
    </motion.div>
  );
};

// User Information Component
const UserInformation: React.FC<{ profile: UserData; onManageBilling: () => void; onStateChange: (state: string) => void }> = ({ profile, onManageBilling, onStateChange }) => {
  // Function to capitalize the first letter of the email
  const capitalizeEmail = (email: string) => {
    return email.charAt(0).toUpperCase() + email.slice(1);
  };

  // Function to get full state name
  const getFullStateName = (stateCode: string) => {
    const stateNames: { [key: string]: string } = {
      'tx': 'Texas', 'ms': 'Mississippi', 'al': 'Alabama', 'ar': 'Arkansas',
      'ca': 'California', 'co': 'Colorado', 'fl': 'Florida', 'ga': 'Georgia',
      'id': 'Idaho', 'in': 'Indiana', 'ia': 'Iowa', 'ky': 'Kentucky',
      'la': 'Louisiana', 'mi': 'Michigan', 'mn': 'Minnesota', 'mo': 'Missouri',
      'mt': 'Montana', 'ne': 'Nebraska', 'nm': 'New Mexico'
    };
    return stateNames[stateCode.toLowerCase()] || stateCode;
  };

  return (
    <motion.div
      className="bg-gray-800 rounded-lg shadow-lg p-6 mb-6 frosted-glass"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <h2 className="text-2xl font-bold text-white mb-4 text-center">User Information</h2>
      <div className="space-y-4">
        <div className="flex items-center">
          <User className="w-6 h-6 text-green-500 mr-2" />
          <p className="text-white font-bold">Email: <span className="text-green-500">{capitalizeEmail(profile.email)}</span></p>
        </div>
        <div className="flex items-center">
          <MapPin className="w-6 h-6 text-green-500 mr-2" />
          <p className="text-white font-bold">State: <span className="text-green-500">{getFullStateName(profile.selectedState)}</span></p>
        </div>
        <div className="flex items-center">
          <DollarSign className="w-6 h-6 text-green-500 mr-2" />
          <p className="text-white font-bold">Subscription Status: <span className="text-green-500">{profile.subStatus}</span></p>
        </div>
        <div className="flex items-center justify-center mt-4">
          <Button onClick={onManageBilling} className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            Manage Billing
          </Button>
        </div>
        <StateSelector selectedState={profile.selectedState} onStateChange={onStateChange} />
      </div>
    </motion.div>
  );
};

// State Selector Component
const StateSelector: React.FC<{ selectedState: string; onStateChange: (state: string) => void }> = ({ selectedState, onStateChange }) => {
  const states = ['AZ', 'CA', 'DC', 'FL', 'IL', 'KS', 'KY', 'MD', 'MO', 'MS', 'NC', 'NM', 'NY', 'OH', 'OK', 'OR', 'TX', 'VA', 'WA'];

  return (
    <div className="mb-4">
      <label htmlFor="state-select" className="block text-sm font-medium text-gray-300 mb-2">
        Select State
      </label>
      <select
        id="state-select"
        value={selectedState.toUpperCase()}
        onChange={(e) => onStateChange(e.target.value.toLowerCase())}
        className="bg-gray-700 text-white rounded-md px-3 py-2 w-full"
      >
        {states.map((state) => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </select>
    </div>
  );
};

// Estimated Savings Component
const EstimatedSavings: React.FC<{ estimatedSavings: number }> = ({ estimatedSavings }) => {
  const [prevSavings, setPrevSavings] = useState(estimatedSavings);
  const piggyAnimation = useAnimation();

  useEffect(() => {
    if (estimatedSavings > prevSavings) {
      // Trigger piggy bank jiggle
      piggyAnimation.start({
        rotate: [0, -5, 5, -5, 5, 0],
        transition: { duration: 0.5 }
      });
    }
    setPrevSavings(estimatedSavings);
  }, [estimatedSavings, prevSavings, piggyAnimation]);

  return (
    <motion.div
      className="bg-gray-800 rounded-lg shadow-lg p-6 frosted-glass"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.4 }}
    >
      <h2 className="text-2xl font-bold text-white mb-4 text-center">Estimated Savings</h2>
      <div className="flex items-center justify-center mb-2">
        <motion.div animate={piggyAnimation}>
          <PiggyBank 
            className="w-16 h-16 text-green-500 mr-6" 
            style={{
              filter: `drop-shadow(0 0 8px rgba(34, 197, 94, 0.5))`,
            }}
          />
        </motion.div>
        <CountUp
          start={prevSavings}
          end={estimatedSavings}
          duration={2}
          separator=","
          decimals={2}
          decimal="."
          prefix="$"
          className="text-5xl font-bold"
        >
          {({ countUpRef }) => (
            <div 
              className="bg-clip-text text-transparent text-4xl font-bold bg-gradient-to-b from-green-300 to-green-600"
              style={{
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              <span ref={countUpRef} />
            </div>
          )}
        </CountUp>
      </div>
      <p className="text-gray-400 text-center mt-2">since joining Scratch Maestro Pro</p>
    </motion.div>
  );
};

// Confirmation Modal Component
const ConfirmationModal: React.FC<{ isOpen: boolean; onClose: () => void; onConfirm: () => void }> = ({ isOpen, onClose, onConfirm }) => {
  const liquidShakeAnimation = {
    hidden: { opacity: 0, scale: 0.9, y: 20 },
    visible: {
      opacity: 1,
      scale: 1,
      y: [20, -5, 0],
      transition: {
        duration: 0.3,
        y: {
          type: "spring",
          damping: 5,
          stiffness: 500,
        },
        opacity: { duration: 0.1 },
        scale: { duration: 0.1 }
      }
    },
    exit: { opacity: 0, scale: 0.9, y: 20, transition: { duration: 0.1 } }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <motion.div 
            className="bg-gray-800 p-6 rounded-lg shadow-lg frosted-glass border border-green-500 max-w-sm w-full mx-4"
            variants={liquidShakeAnimation}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <h3 className="text-xl font-bold text-white mb-4">Confirm Sign Out</h3>
            <p className="text-gray-300 mb-6">Are you sure you want to sign out?</p>
            <div className="flex justify-end space-x-4">
              <button 
                onClick={onClose}
                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
              >
                Cancel
              </button>
              <button 
                onClick={onConfirm}
                className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors"
              >
                Sign Out
              </button>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

// Updated Sign Out Button Component
const SignOutButton: React.FC<{ onSignOut: () => void }> = ({ onSignOut }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleConfirmSignOut = () => {
    closeModal();
    onSignOut();
  };

  return (
    <>
      <motion.div
        className="mt-8 w-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <Button onClick={openModal} className="w-full">
          <LogOut className="w-5 h-5 mr-2" />
          Sign Out
        </Button>
      </motion.div>
      <ConfirmationModal 
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleConfirmSignOut}
      />
    </>
  );
};

// New component for additional links
const AdditionalLinks: React.FC = () => (
  <motion.div
    className="bg-gray-800 rounded-lg shadow-lg p-6 mb-6 frosted-glass"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.6 }}
  >
    <h2 className="text-2xl font-bold text-white mb-4 text-center">Additional Resources</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <LinkButton to="/contact" icon={Mail} text="Contact Us" />
      <LinkButton to="/terms" icon={FileText} text="Terms of Service" />
      <LinkButton to="/privacy" icon={Shield} text="Privacy Policy" />
    </div>
  </motion.div>
);

// New component for link buttons
const LinkButton: React.FC<{ to: string; icon: React.ElementType; text: string }> = ({ to, icon: Icon, text }) => (
  <Link to={to}>
    <motion.div
      className="flex items-center p-4 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors duration-300"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <Icon className="w-6 h-6 text-green-500 mr-2" />
      <span className="text-white font-bold text-left">{text}</span>
    </motion.div>
  </Link>
);

export default ProfilePage;